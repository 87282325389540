import { innerPromotionActionTypesEnum as ctaActionTypes } from '../../../shared/src/enums/promotions.enum';
import { AppActionEnum } from '../../../shared/src/enums/app-action.enum';
import { PamAsgRestapiService } from './pam-asg-restapi.service';

export const External1LoyaltyService = {
    getPreLoginData() {
        const {
            stickyStrip,
            imageTextSection,
            paragraphs,
            accordions,
        } = window?.externalLoyaltyData?.preLogin;

        if (stickyStrip.link) {
            stickyStrip.ctaAction = ctaActionTypes.LINK;
            stickyStrip.ctaActionValue = stickyStrip.link;
        } else {
            stickyStrip.ctaAction = ctaActionTypes.WIDGET;
            stickyStrip.ctaActionValue = AppActionEnum.REGISTRATION;
        }

        return {
            stickyStrip,
            imageTextSection,
            paragraphs,
            accordions,
        };
    },

    getPostLoginData(apiData) {
        const {
            topFold,
            stickyStrip,
            locations,
            playerRewards,
        } = window?.externalLoyaltyData?.postLogin;

        stickyStrip.ctaAction = ctaActionTypes.LINK;
        stickyStrip.ctaActionValue = stickyStrip.link;

        playerRewards.freeSlotPlay.amount = apiData.rewardsAmounts.freeSpins;
        playerRewards.comps.amount = apiData.rewardsAmounts.comp;
        playerRewards.flexPoints.amount = apiData.rewardsAmounts.flexPoints;

        return {
            topFold,
            stickyStrip,
            locations,
            playerRewards,
        };
    },

    async fetchApiData() {
        const encryptedPlayerId = AsgFW.getSessionDetails().EncryptedUserID;
        const apiEndpoint = `/v1/player/${encryptedPlayerId}/LCH/GetPlayerLoyaltyData`;

        const sessionToken = AsgFW.getSessionDetails().SessionToken;
        const brandId = AsgFW.getBrandID();
        const language = AsgFW.getLanguage();
        const platform = AsgFW.getPamPlatform();

        const extraHeaders = {
            Authorization: sessionToken,
            'X-Brand-ID': brandId,
            'X-Language-Code': language,
            'X-Platform-Type': platform,
        };
        const responseJson = await PamAsgRestapiService.postRequest(apiEndpoint, '', extraHeaders);
        const playerProfileData = responseJson?.playerProfile;

        return playerProfileData ? {
            cardNumber: playerProfileData.liveRewardsID,
            registrationDate: playerProfileData.registeredDateTime,
            pointsExpirationDate: playerProfileData.currentTierEndDate,
            currentLevelNumber: playerProfileData.currentTierNumber,
            currentLevelPoints: playerProfileData.currentTierCredits,
            nextLevelPoints: playerProfileData.nextTierPoints,
            rewardsAmounts: playerProfileData.rewardsAmounts,
        } : false;
    },
};
