import { createAsyncThunk } from '@reduxjs/toolkit';
import { JackpotsService } from '../../services/jackpots.service';
import { verticalActionTypesEnum, verticalTypesEnum } from '../../enums/vertical-types.enum';
import { useAsgActions } from '../../hooks/use-asg-actions.hook';

export const getJackpots = createAsyncThunk(
    'jackpots/fetchJackpots',
    async (requestParams, { getState, dispatch }) => {
        try {
            const { fillVerticals } = useAsgActions();
            const { app: {
                config: { templateDirectoryUri },
                verticals,
            } } = getState();
            const { data } = await JackpotsService.getJackpots(requestParams);

            const jackpotsFiltered = await JackpotsService.processJackpotsApiResponse({
                verticals,
                verticalTypesEnum,
                verticalActionTypesEnum,
                games: AsgFW.getGamesList().instant,
                jackpots: data.jackpots,
                jackpotsByOrder: data.byOrder,
                jackpotsBanners: data.banners,
                jackpotsCategories: data.categories,
                themePublicPath: templateDirectoryUri,
            });

            dispatch(fillVerticals({
                byOrder: jackpotsFiltered.verticals,
            }));

            return {
                ...jackpotsFiltered,
                winners: data.winners,
                maturityThresholds: data.maturityThresholds,
            };
        } catch {
            return null;
        }
    }
);
