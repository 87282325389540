import { ltPromotionStatusesEnum, ltPromotionActionCodesEnum } from '../enums/promotions.enum';
import { playerSlice } from '../store/player/reducer';
import { loadGameAction } from '../store/casino/actions';
import { appSlice } from '../store/app/reducer';

export const PromotionsService = {
    getPromotionsList(requestParams) {
        return new Promise((resolve, reject) => {
            SolarAPI.get(
                '/api/v1/promotions/limited-time',
                requestParams,
                (response) => {
                    resolve(response);
                },
                (e) => {
                    Tools.error('Promotions list request failed', e);
                    reject(e);
                },
            );
        });
    },

    getFoldColorsVariables(colorSettings) {
        return 'brand' === colorSettings.colorTheme ? {} : {
            '--lt-promotions-thumbnail-main-bg-color': colorSettings.background,
            '--lt-promotions-thumbnail-text-color': colorSettings.text,
            '--lt-promotions-thumbnail-cta-bg-color': colorSettings.ctaBackground,
            '--lt-promotions-thumbnail-cta-color': colorSettings.ctaText,
            '--lt-promotions-thumbnail-clock-color': colorSettings.ctaCountdown,
            '--lt-promotions-thumbnail-tnc-color': colorSettings.questionsText,
        };
    },

    parsePromotionDate(dateString) {
        const [date] = dateString.split(' ');
        const [day, month,  year] = date.split('/');
        return new Date(year, (parseInt(month, 10) - 1), parseInt(day, 10));
    },

    formatPromotionDate(dateObj, lang) {
        return new Intl.DateTimeFormat(
            `${lang ?? 'en'}-GB`,
            { dateStyle: 'long' },
        ).format(dateObj);
    },

    getPromotionsStatus({
        liveModeStartDate,
        endDate,
    }) {
        // ongoing mode
        if (!liveModeStartDate && !endDate) {
            return ltPromotionStatusesEnum.ONGOING;
        }

        const now = new Date();
        const endDateParsed = new Date(endDate);

        // expired mode
        if (now > endDateParsed) {
            return ltPromotionStatusesEnum.EXPIRED;
        }

        if (liveModeStartDate) {
            const liveDateParsed = new Date(liveModeStartDate);

            // live mode
            if (liveDateParsed < now && now < endDateParsed) {
                return ltPromotionStatusesEnum.LIVE;
            }

            // countdown mode till Live start
            return ltPromotionStatusesEnum.COUNTDOWN_TILL_LIVE;
        }

        // countdown mode till expired
        return ltPromotionStatusesEnum.COUNTDOWN_TILL_END;
    },

    isCountdownState(status) {
        return [
            ltPromotionStatusesEnum.COUNTDOWN_TILL_END,
            ltPromotionStatusesEnum.COUNTDOWN_TILL_LIVE,
        ].includes(status);
    },

    isShowStateIndicator(status, isShowCountdown) {
        return !this.isCountdownState(status) || isShowCountdown;
    },

    execPromotionCta(actionObject, dispatch) {
        const goToAction = () => {
            dispatch(appSlice.actions.goTo(actionObject.target));
        };

        const actionsMap = {
            [ltPromotionActionCodesEnum.REGISTRATION]: () => {
                dispatch(playerSlice.actions.showLoginOrRegistration());
            },
            [ltPromotionActionCodesEnum.GAME]: () => {
                dispatch(loadGameAction({
                    gameId: actionObject.target,
                }));
            },
            [ltPromotionActionCodesEnum.PROVIDER_PAGE]: goToAction,
            [ltPromotionActionCodesEnum.SPORT_LEAGUE]: goToAction,
            [ltPromotionActionCodesEnum.SHOW_DEPOSIT]: () => {
                dispatch(appSlice.actions.showDeposit());
            },
        };

        const action = actionsMap[actionObject.name];

        if (action) {
            action();
        }
    },

    getSecondaryPromotionsLayout(secondaryPromotions) {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const isLandScape = screenWidth > screenHeight;
        const isPortrait = screenHeight > screenWidth;

        // portrait + <1600 screens - all small items
        if (isPortrait || isLandScape && screenWidth < 1600) {
            return {
                bigThumbnails: [],
                smallThumbnails: secondaryPromotions,
            };
        }

        /**
         * Calculates how many big secondary promotions should be in layout
         * Short logic description:
         *  - In most cases layout should be split by 3 from the bottom to top
         *  - The top reminders from division by 3 are big thumbnails
         *
         * @link https://aspireglobal.atlassian.net/l/cp/d0zwmyNi#Secondary-promotions-layout
         */
        const calculateBigThumbnails = (promotionsAmount, origAmount = null) => {
            if (origAmount === null
                && (promotionsAmount < 3 || promotionsAmount === 4)) {
                return promotionsAmount;
            }

            const slice = promotionsAmount - 3;

            if (slice >= 3) {
                return calculateBigThumbnails(slice, promotionsAmount);
            }

            if (slice === 1) {
                return origAmount - 3;
            }

            if (slice === 0) {
                return 0;
            }

            // is even
            if (slice % 2 === 0) {
                return slice;
            }

            return slice;
        };

        const bigThumbnailsAmount = calculateBigThumbnails(secondaryPromotions.length);

        const bigThumbnails = secondaryPromotions.slice(0, bigThumbnailsAmount);
        const smallThumbnails = secondaryPromotions.slice(bigThumbnailsAmount);

        return {
            bigThumbnails,
            smallThumbnails,
        };
    },
};
