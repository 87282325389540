import { createSlice } from '@reduxjs/toolkit';
import { LoyaltyPlansEnum } from '../../../../loyalty/src/enums/loyalty-plans.enum';
import { RequestStatusesEnum } from '../../enums/request-statuses.enum';
import { getExternal1LoyaltyData } from './actions';

const initialState = {
    [LoyaltyPlansEnum.EXTERNAL_1]: {
        status: RequestStatusesEnum.DEFAULT,
        data: null,
    },
};

export const loyaltySlice = createSlice({
    name: 'loyalty',
    initialState,
    reducers: {
        setLoyaltyWaitingStatus(state, action) {
            const planCode = action.payload;
            state[planCode].status = RequestStatusesEnum.WAITING;
        },
    },
    extraReducers: (builder) => {
        const setDataAndStatus = (state, action) => {
            const { planCode, data } = action.payload;
            state[planCode].data = data;
            state[planCode].status = data
                ? RequestStatusesEnum.SUCCEDED
                : RequestStatusesEnum.FAILED;
        };

        builder.addCase(getExternal1LoyaltyData.fulfilled, setDataAndStatus);
    },
});
