export const DateUtilService = {
    /**
     * Calculate the time between param timeStart and param timeEnd.
     * Return object with the current state of time.
     */
    getTimeMeasure(timeStartTimestamp, timeEndTimestamp) {
        const dateStart = new Date(timeStartTimestamp);
        const dateEnd = new Date(timeEndTimestamp);

        const difference = dateEnd - dateStart;
        let seconds = Math.floor((dateEnd - (dateStart)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        hours -= (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

        return {
            seconds,
            minutes,
            hours,
            days,
            difference,
        };
    },

    getDateTimeParts(timestamp) {
        const dateObject = new Date(timestamp);

        if (isNaN(dateObject)) {
            throw new Error('Wrong timestamp');
        }

        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const dayOfMonth = dateObject.getDate();
        const hours24format = dateObject.getHours();
        const hours12format = hours24format > 12 ? hours24format - 12 : hours24format;
        const dayPeriod = hours24format > 12 ? 'pm' : 'am';
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();

        return {
            year,
            month,
            dayOfMonth,
            hours24format,
            hours12format,
            dayPeriod,
            minutes,
            seconds,
        };
    },

    getZeroLeadingDateTimeParts(timestamp) {
        let dateTimeParts;

        try {
            dateTimeParts = this.getDateTimeParts(timestamp);
        } catch (e) {
            Tools.error(e.message);
            return null;
        }

        const { year, dayPeriod } = dateTimeParts;
        let { month, dayOfMonth, hours24format, hours12format, minutes, seconds } = dateTimeParts;

        month = this.addLeadingZero(month);
        dayOfMonth = this.addLeadingZero(dayOfMonth);
        hours24format = this.addLeadingZero(hours24format);
        hours12format = this.addLeadingZero(hours12format);
        minutes = this.addLeadingZero(minutes);
        seconds = this.addLeadingZero(seconds);

        return {
            year,
            month,
            dayOfMonth,
            hours24format,
            hours12format,
            dayPeriod,
            minutes,
            seconds,
        };
    },

    addLeadingZero(numberValue) {
        let stringValue = numberValue.toString();
        if (numberValue < 10) {
            return '0' + stringValue;
        }
        return stringValue;
    },
};
